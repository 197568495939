import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "text"
    }}>{`Text`}</h1>
    <p>{`Documentation and examples for common text utilities.`}</p>
    <h2 {...{
      "id": "text-alignment"
    }}>{`Text Alignment`}</h2>
    <p>{`Text-align are available like:`}</p>
    <p className='text-center background-white black border-radius p-2'>I`m a centered text</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<p class='text-center'>I\`m a centered text</p>
`}</code></pre>
    <p>{`Some values for `}<inlineCode parentName="p">{`text-align`}</inlineCode>{` are available with classes:`}</p>
    <table className='background-white black p-2 border-radius' style={{
      "maxWidth": "400px"
    }}>
    <thead>
        <tr>
            <th>
                Property
            </th>
            <th>
                Class
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>
                center
            </td>
            <td>
                text-center
            </td>
        </tr>
        <tr>
            <td>
                left
            </td>
            <td>
                text-left
            </td>
        </tr>
        <tr>
            <td>
                right
            </td>
            <td>
                text-right
            </td>
        </tr>
        <tr>
            <td>
                justify
            </td>
            <td>
                text-justify
            </td>
        </tr>
    </tbody>
    </table>
    <h2 {...{
      "id": "text-weight"
    }}>{`Text Weight`}</h2>
    <p>{`Text-weight are available like:`}</p>
    <p className='text-bold background-white black border-radius p-2'>I`m a bold text</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<p class='text-bold'>I\`m a bold text</p>
`}</code></pre>
    <p>{`Some values for `}<inlineCode parentName="p">{`text-weight`}</inlineCode>{` are available with classes:`}</p>
    <table className='background-white black p-2 border-radius' style={{
      "maxWidth": "400px"
    }}>
    <thead>
        <tr>
            <th>
                Weight
            </th>
            <th>
                Class
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>
                100
            </td>
            <td>
                text-thin
            </td>
        </tr>
        <tr>
            <td>
                300
            </td>
            <td>
                text-light
            </td>
        </tr>
        <tr>
            <td>
                400
            </td>
            <td>
                text-regular
            </td>
        </tr>
        <tr>
            <td>
                500
            </td>
            <td>
                text-medium
            </td>
        </tr>
        <tr>
            <td>
                700
            </td>
            <td>
                text-bold
            </td>
        </tr>
        <tr>
            <td>
                900
            </td>
            <td>
                text-black
            </td>
        </tr>
    </tbody>
    </table>
    <h2 {...{
      "id": "text-elipsis"
    }}>{`Text Elipsis`}</h2>
    <p>{`To add an ellipsis at the end of the text use the following classes:`}</p>
    <p className='text-ellipsis background-white black border-radius p-2' style={{
      "maxWidth": "90px"
    }}>I`m a One line ellipsis</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<p class='text-ellipsis' style='max-width: 90px;'>I\`m a One line ellipsis</p>
`}</code></pre>
    <p>{`If the text has more than one line use:`}</p>
    <p className='text-ellipsis-more background-white black border-radius p-2' style={{
      "maxWidth": "50px",
      "maxHeight": "57px"
    }}>I`m a More than line ellipsis</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<p class='text-ellipsis-more' style='max-width: 50px; max-height: 57px'>I\`m a More than line ellipsis</p>
`}</code></pre>
    <p>{`This classe use `}<inlineCode parentName="p">{`-webkit-line-clamp: 3`}</inlineCode>{` to change the clip on more lines change this prop on your element`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      